import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
// sections
import MemberListView from 'src/sections/member/view/member-list-view';

// ----------------------------------------------------------------------

export default function MemberListPage({ status = 'active' }) {
  return (
    <>
      <Helmet>
        <title>Miemnbros</title>
      </Helmet>

      <MemberListView status={status} />
    </>
  );
}

MemberListPage.propTypes = {
  status: PropTypes.string,
};