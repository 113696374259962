import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: HOST_API });

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const accessToken = localStorage.getItem('accessToken');
        const currentRefreshToken = localStorage.getItem('refreshToken');
        const { id, currentInstitution } = jwtDecode(accessToken);
        const response = await axiosInstance.post('/auth/refresh', {
          refreshToken: currentRefreshToken,
          id,
          currentInstitution
        });
        const { token, refreshToken } = response.data;

        const responseToken = jwtDecode(token);
        localStorage.setItem('institutions', JSON.stringify(responseToken.institutions.map((item) => item.id === responseToken.currentInstitution ? { ...item, current: true } : item)))
        // Set new tokens.
        localStorage.setItem('accessToken', token)
        localStorage.setItem('refreshToken', refreshToken)
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        // Handle refresh token error or redirect to login
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

// ---
export function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/auth/email/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  user: {
    list: '/users',
    create: '/users',
    update: '/users',
    delete: '/users'
  },
  member: {
    list: '/members',
    create: '/members',
    createImage: '/members/image',
    update: '/members',
    delete: '/members'
  },
  order: {
    list: '/orders',
    create: '/orders',
    delete: '/orders'
  },
  service: {
    list: '/services',
    create: '/services',
    delete: '/services'
  },
  settings: {
    list: 'settings',
    update: '/settings'
  },
  ad: {
    list: '/ad',
    create: '/ad',
    delete: '/ad'
  },
  coupon: {
    list: '/coupons',
    create: '/coupons',
    delete: '/coupons'
  },
  address: {
    list: '/addresses',
    create: '/addresses',
    delete: '/addresses'
  },
  vehicle: {
    list: '/vehicles',
    create: '/vehicles',
    delete: '/vehicles'
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
