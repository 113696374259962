import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { USER_GENDER_OPTIONS, USER_ROLE_OPTIONS, USER_STATUS_OPTIONS } from 'src/_mock/_user';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField, RHFAutocomplete, RHFMultiSelect, RHFUploadAvatar } from 'src/components/hook-form';
import axios, { endpoints } from 'src/utils/axios';
import { Typography } from '@mui/material';
import { fData } from 'src/utils/format-number';

// ----------------------------------------------------------------------

export default function MemberQuickEditForm({ currentUser, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  // const getRoles = (roles) => roles.map((role) => (role.id));
  const setObjectRoles = (roles) => {
    const outputRoles = USER_ROLE_OPTIONS.filter((role) => (roles.some(roleItem => (role.value === roleItem))));
    return outputRoles.map(role => ({ id: role.value, name: role.label }))
  }

  const NewUserSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phone: Yup.string().required('Phone number is required'),
  });

  const defaultValues = useMemo(
    () => ({
      fullName: currentUser?.fullName || '',
      email: currentUser?.email || '',
      phone: currentUser?.phone || '',
      status: currentUser?.status || '',
      gender: currentUser?.gender || '',
      role: currentUser?.role || [],
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      data.role = setObjectRoles(data.role);
      await axios.put(`${endpoints.user.update}/${currentUser._id}`, data)
      reset();
      onClose();
      enqueueSnackbar('Update success!');
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const uploadImage = useCallback(
    async (file) => {
      const bodyContent = new FormData();
      bodyContent.append("institution", 'atleticoalianza');
      bodyContent.append("file", file);
      const { data } = await axios.post(endpoints.member.createImage, bodyContent);
      setValue('image', {
        src: data.public_id,
        id: data.asset_id,
        format: data.format
      })
    }, [setValue]
  );

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        uploadImage(file);
        setValue('avatarUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue, uploadImage]
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Actualizar</DialogTitle>

        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
            Revise bien los datos antes de guardar
          </Alert>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Tipos permitidos *.jpeg, *.jpg, *.png
                    <br /> tamaño maximo {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
            <RHFSelect name="status" label="Status">
              {USER_STATUS_OPTIONS.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </RHFSelect>

            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />

            <RHFTextField name="fullName" label="Full Name" />
            <RHFTextField name="email" label="Email Address" />
            <RHFTextField name="phone" label="Phone Number" />
            <RHFSelect native name="gender" label="Gender" InputLabelProps={{ shrink: true }}>
              {USER_GENDER_OPTIONS.map((gender) => (
                <option key={gender.value} value={gender.value}>
                  {gender.label}
                </option>
              ))}
            </RHFSelect>
            <RHFMultiSelect chip checkbox name="role" label="Roles" options={USER_ROLE_OPTIONS} />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

MemberQuickEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
