import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

/*
export const useUserStore = create((set) => ({
  user: null,
  updateUser: (newUser) => set({ user: newUser }),
  removeUser: () => set({ user: null })
}))
*/

export const useUserStore = create(
  persist(
    (set, get) => ({
      user: null,
      updateUser: (dataUser) => set({ user: dataUser }),
      removeUser: () => set({ user: null })
    }),
    {
      name: 'user-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
)