import { useMutation } from '@apollo/client';
import { CREATE } from 'src/graphql/profile';

// Definimos el hook para crear el Member
export const useCreateMember = () => {
  const [createMember, { data, loading, error }] = useMutation(CREATE, {
    onCompleted: (dataMember) => {
      console.log('Member created successfully:', dataMember);
    },
    onError: (err) => {
      console.error('Error creating member:', err);
    },
  });

  // Esta función recibe los datos del nuevo Member y ejecuta la mutación
  const handleCreateMember = (object) => {
    createMember({
      variables: {
        object
      },
    });
  };

  return {
    handleCreateMember,
    data,
    loading,
    error,
  };
};