import { Helmet } from 'react-helmet-async';
// routes
import { useParams } from 'src/routes/hook';
// sections
import MemberEditView from 'src/sections/member/view/member-edit-view';

// ----------------------------------------------------------------------

export default function MemberEditPage() {
  const params = useParams();
  const { id } = params;

  return (
    <>
      <Helmet>
        <title>Editar</title>
      </Helmet>

      <MemberEditView id={`${id}`} />
    </>
  );
}
