import { useContext } from 'react';
import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// auth
import { useUserStore } from 'src/stores/store';
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import axiosInstance, { jwtDecode } from 'src/utils/axios';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: paths.dashboard.user.profile,
  },
  {
    label: 'Settings',
    linkTo: paths.dashboard.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const user = useUserStore((state) => state.user)
  const router = useRouter();

  // const { user } = useMockedUser();

  const { logout } = useAuthContext();

  const institutions = JSON.parse(localStorage.getItem('institutions'));
  const currentInstitution = institutions?.find(item => item.current);

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  const changeInstitution = async (institutionId) => {
    const accessToken = localStorage.getItem('accessToken');
    const currentRefreshToken = localStorage.getItem('refreshToken');
    const { id } = jwtDecode(accessToken);
    const response = await axiosInstance.post('/auth/refresh', {
      refreshToken: currentRefreshToken,
      id,
      currentInstitution: institutionId
    });
    const { token, refreshToken } = response.data;

    const responseToken = jwtDecode(token);
    localStorage.setItem('institutions', JSON.stringify(responseToken.institutions?.map((item) => item.id === responseToken.currentInstitution ? { ...item, current: true } : item)))

    // Set new tokens.
    localStorage.setItem('accessToken', token)
    localStorage.setItem('refreshToken', refreshToken)
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    window.location.reload('/dashboard')
  }

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={currentInstitution?.logo}
          alt={currentInstitution?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentInstitution?.name}
          </Typography>

          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography> */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {/* {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))} */}
          {institutions?.map((option) => !option.current && (
            <MenuItem key={option.id} onClick={() => changeInstitution(option.id)}>
              Cambiar a {option.name}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Salir
        </MenuItem>
      </CustomPopover>
    </>
  );
}
