// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import { useSearchParams } from 'src/routes/hook';
import { MEMBER_TYPE_ENUMS } from 'src/_mock';
import MemberNewEditForm from '../member-new-edit-form';

// ----------------------------------------------------------------------
export default function MemberCreateView() {
  const settings = useSettingsContext();
  const searchParams = useSearchParams();
  const type = searchParams.get('type');

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={`Agregar ${MEMBER_TYPE_ENUMS[type]}`}
        links={[
          {
            name: 'Inicio',
            href: paths.dashboard.root,
          },
          {
            name: 'Miembros',
            href: paths.dashboard.member.root,
          },
          { name: `Agregar ${MEMBER_TYPE_ENUMS[type]}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <MemberNewEditForm type={type} />
    </Container>
  );
}
