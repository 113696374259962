import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFSwitch } from 'src/components/hook-form';
import axios, { endpoints } from 'src/utils/axios';
import { Stack } from '@mui/system';
import { MEMBER_CATEGORY_OPTIONS, MEMBER_CATEGORY_SOCIO_OPTIONS, MEMBER_PAYMENT_METHODS_OPTIONS, MEMBER_QUANTITY_OPTIONS } from 'src/_mock';
import { Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

export default function MemberQuickPayForm({ currentMember, open, onClose }) {


  let prices;
  const options = currentMember.type === 'socio' ? MEMBER_CATEGORY_SOCIO_OPTIONS : MEMBER_CATEGORY_OPTIONS;
  const hasCategory = options?.find(
    category => category.value === currentMember?.info?.category
  )

  if (hasCategory) {
    prices = hasCategory.subcategories?.find(subcategory => subcategory.value === currentMember?.info?.subcategory);
  }


  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    // installments: Yup.number().required('Seleccione la cantidad de cuotas.'),
    // paymentMethod: Yup.string().required('Seleccione un metodo de pago'),
  });

  const defaultValues = useMemo(
    () => ({
      firstPay: new Date(),
      inscription: currentMember?.inscription || false,
      installments: currentMember?.installments || 1,
      paymentMethod: currentMember?.paymentMethod || 'cash'
    }),
    [currentMember]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      data.member = {
        id: currentMember._id,
        names: currentMember.profileId.names,
        lastName: currentMember.profileId.lastName,
        phone: currentMember.profileId.phone,
        email: currentMember.profileId.email,
        type: currentMember.type,
        info: currentMember.info
      }

      data.new = (!currentMember?.lastPay);
      data.lastPay = !currentMember?.lastPay ? convertToDate(data.firstPay) : currentMember.lastPay;
      delete data.firstPay;
      const response = await axios.post(endpoints.order.create, data)
      reset();
      enqueueSnackbar('El pago se guardo correctamente!');
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    } catch (error) {
      console.error(error);
    }
  });

  const convertToDate = (date) => {
    date.setMonth(date.getMonth() - 1);
    const dateObj = new Date(date);
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const year = dateObj.getUTCFullYear();

    return +`${year}${month}`;
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Abonar cuotas</DialogTitle>

        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
            Rellene cuidadosamente los siguientes campos.
          </Alert>

          {!currentMember.lastPay && <Stack spacing={1.5} mb={2} columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}>
            <Typography variant="subtitle2">Seleccione el mes inicial de pago</Typography>
            <Controller
              name="firstPay"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  format="MM/yyyy"
                  views={['month', 'year']}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </Stack>}

          <Stack
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}>
            <RHFSelect name="installments" label="Cuotas a pagar?" sx={{ mb: 3 }}>
              {MEMBER_QUANTITY_OPTIONS.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </RHFSelect>

            <Stack sx={{ mt: 2 }}>
              Total: ${+values.installments * (prices?.amount ? prices.amount : 0)}
            </Stack>
          </Stack>

          <Stack
            sx={{ mb: 4 }}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}>

            <RHFSelect name="paymentMethod" label="Forma de pago" sx={{ mb: 3 }}>
              {MEMBER_PAYMENT_METHODS_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>

            <Stack sx={{ mt: 2 }}>
              {values.paymentMethods}
            </Stack>
          </Stack>

          <RHFSwitch
            name="inscription"
            checked={values.inscription}
            label={`Abonara inscripción ($${prices?.amountInscription})?`}
            sx={{ mb: 3 }}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting} color='success'>
            Pagar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

MemberQuickPayForm.propTypes = {
  currentMember: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
