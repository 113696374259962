import { Helmet } from 'react-helmet-async';
// sections
import MemberProfileView from 'src/sections/member/view/member-profile-view';

// ----------------------------------------------------------------------

export default function MemberProfilePage() {
  return (
    <>
      <Helmet>
        <title>Perfil</title>
      </Helmet>

      <MemberProfileView />
    </>
  );
}
