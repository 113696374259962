import { useSuspenseQuery } from '@apollo/client';
import { DETAIL } from '../../graphql/members'; // Importa el query que definimos anteriormente

export const useMember = ({ id }) => {
  const { loading, error, data } = useSuspenseQuery(DETAIL, { variables: { id } });

  return {
    loading,
    error,
    member: data ? data.getMember : [],
  };
};