import { useQuery } from '@apollo/client';
import { GET_MEMBERS } from '../../graphql/members'; // Importa el query que definimos anteriormente

export const useMembers = () => {
  const { loading, error, data } = useQuery(GET_MEMBERS);

  return {
    loading,
    error,
    members: data ? data.members : [],
  };
};