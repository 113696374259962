import { Helmet } from 'react-helmet-async';
// sections
import MemberCreateView from 'src/sections/member/view/member-create-view';

// ----------------------------------------------------------------------

export default function MemberCreatePage() {
  return (
    <>
      <Helmet>
        <title>Crear nuevo miembro</title>
      </Helmet>

      <MemberCreateView />
    </>
  );
}
