import { gql } from '@apollo/client';

export const GET_MEMBERS = gql`
  query GetMembers {
    members {
      keywords
      temporal
      options
      category
      status
      subcategory
      id
      institution_id
      member_type_id
      profile_id
      profile {
        id
        names
        last_name
        phone
        id_number
        image
      }
    }
  }
`;

export const DETAIL = gql`
  query GetMember($id: String!) {
    getMember(id: $id) {
      id
      profile {
        id
        last_name
        names
        id_number
      }
    }
  }
`;

export const EDIT = gql`
  mutation EditMember($id: String!, $changes: EditMemberInput!) {
    editMember(id: $id, changes: $changes) {
      id
      status
    }
  }
`;