import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet } from '@react-pdf/renderer';
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
import { getLastPay } from 'src/utils/helpers';

// ----------------------------------------------------------------------
const baseImgUrl = "https://res.cloudinary.com/sanjua/image/upload/v1688669219/";

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col4: { width: '25%' },
        col5: { width: '33%' },
        col6: { width: '50%' },
        col8: { width: '75%' },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Roboto',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 120px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          padding: '8px 0',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#DFE3E8',
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '5%',
        },
        tableCell_2: {
          width: '50%',
          paddingRight: 16,
        },
        tableCell_3: {
          width: '15%',
        },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function InvoicePDF({ invoice, currentStatus }) {
  const {
    items,
    discount,
    createDate,
    totalAmount,
    subTotal,
  } = invoice;

  const styles = useStyles();

  const getMonthPaid = (lastPay, row) => {
    const lastYear = lastPay.toString().slice(0, 4);
    const lastMonth = lastPay.toString().slice(4);
    const date = new Date(+lastYear, +lastMonth + row);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');

    return getLastPay(year, month);
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col5}>
            <Image
              source={`${baseImgUrl}${invoice?.institutionId?.logo}`}
              style={{ width: 100, height: 100 }}
            />
          </View>

          <View style={styles.col5}>
            <View style={[styles.col6, styles.mb4]}>
              <Text style={[styles.subtitle2, styles.mb4]}>Fecha: {fDate(invoice?.createdAt)}</Text>
            </View>

            <Text style={[styles.subtitle2, styles.mb4]}>De:</Text>
            <Text style={styles.body2}>{invoice?.institutionId?.name}</Text>
            <Text style={styles.body2}>{invoice?.institutionId?.address}</Text>
            <Text style={styles.body2}>{invoice?.institutionId?.phone}</Text>
          </View>

          <View style={styles.col5}>
            <View style={[styles.col6, styles.mb4]}>
              <Text style={[styles.subtitle2, styles.mb4]}>Factura #{invoice.orderId} </Text>
            </View>
            <Text style={[styles.subtitle2, styles.mb4]}>Para:</Text>
            <Text style={styles.body2}>{invoice.member.names} {invoice.member.lastName}</Text>
            <Text style={styles.body2}>{invoice.member.phone}</Text>
          </View>
        </View>

        {/* <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>Fecha</Text>
            <Text style={styles.body2}>{fDate(invoice?.createdAt)}</Text>
          </View>
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text>Factura #{invoice.orderId} </Text>
          </View>
        </View> */}

        <Text style={[styles.subtitle1, styles.mb8]}>Detalle</Text>

        <View style={styles.table}>
          <View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Descripcion</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Categoria</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Precio unitatio</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Total</Text>
              </View>
            </View>
          </View>

          <View>
            {[...Array(invoice.installments).keys()].map((item, index) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={styles.tableCell_1}>
                  <Text>{index + 1}</Text>
                </View>

                <View style={styles.tableCell_2}>
                  <Text style={styles.subtitle2}>{invoice.member.names} {invoice.member.lastName}</Text>
                  <Text>Cuota: {getMonthPaid(invoice.lastPay, item)}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{invoice.member.info.category} / {invoice.member.info.subcategory}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{fCurrency(invoice.amount)}</Text>
                </View>

                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{fCurrency(invoice.amount * 1)}</Text>
                </View>
              </View>
            ))}

            {/* <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Subtotal</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{fCurrency(subTotal)}</Text>
              </View>
            </View> */}

            {/* <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Shipping</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{fCurrency(-shipping)}</Text>
              </View>
            </View> */}

            {/* <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Descuento</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{fCurrency(-discount)}</Text>
              </View>
            </View> */}

            {/* <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Taxes</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{fCurrency(taxes)}</Text>
              </View>
            </View> */}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text style={styles.h4}>Total</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h4}>{fCurrency(invoice.amount * invoice.installments)}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.footer]} fixed>
          <View style={styles.col8}>
            <Text style={styles.subtitle2}>Nota</Text>
            <Text>
              Metodo de pago:  {invoice.paymentMethod === 'cash' ? 'Efectivo' : 'Mercado Pago / Transferencia'}
            </Text>
          </View>
          {/* <View style={[styles.col4, styles.alignRight]}>
            <Text style={styles.subtitle2}>Tiene una consulta?</Text>
            <Text>email@instituciones.app</Text>
          </View> */}
        </View>
      </Page>
    </Document>
  );
}

InvoicePDF.propTypes = {
  currentStatus: PropTypes.string,
  invoice: PropTypes.object,
};
