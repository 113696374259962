import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

const client = new ApolloClient({
  uri: 'http://localhost:3000/graphql', // URL de tu API GraphQL
  cache: new InMemoryCache(),
});

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
