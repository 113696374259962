export function renderLastPay(value) {
  const newValue = value.toString();
  const year = newValue.slice(0, 4);
  const month = newValue.slice(4);

  return `${MONTHS[month]} / ${year}`;
}

export function getLastPay(year, month) {
  return `${MONTHS[month]} / ${year}`;
}

export const MONTHS = {
  '01': 'Ene',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Abr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Ago',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
}