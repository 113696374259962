import { Helmet } from 'react-helmet-async';
// sections
import MemberCardsView from 'src/sections/member/view/member-cards-view';

// ----------------------------------------------------------------------

export default function MemberCardsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Member Cards</title>
      </Helmet>

      <MemberCardsView />
    </>
  );
}
