import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// _mock
import { _invoices } from 'src/_mock';
// components
import axiosInstance, { endpoints } from 'src/utils/axios';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import InvoiceDetails from '../invoice-details';

// ----------------------------------------------------------------------

export default function InvoiceDetailsView({ id }) {
  const settings = useSettingsContext();
  const [currentInvoice, setCurrentInvoice] = useState(null);

  useEffect(() => {
    fetchInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchInvoice = async () => {
    const response = await axiosInstance.get(`${endpoints.order.list}/${id}`);
    setCurrentInvoice(response.data);
  }

  if (currentInvoice) {
    return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <br /><br /><br />
        <InvoiceDetails invoice={currentInvoice} />
      </Container>
    )
  }

  return (
    <Container />
  );
}

InvoiceDetailsView.propTypes = {
  id: PropTypes.string,
};
